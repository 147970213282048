import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import '../../../src/Popup.css';
import NotyfContext from '../../Context/NotyfContext';
import { Axios } from '../../config';
import CardRetour from './CardRetour';

export default function CardMovStock({
  btupdateLoanId,
  valitem,
  setLoanlist,
  setUpdateCde,
  user,
  onReset,
  loanlist,
  onFinishFailed,
}) {
  const [dateprev, setDatePrev] = useState('');
  const daterecep = '';

  const { RangePicker } = DatePicker;
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  const onDatePrevChange = (date, dateString) => {
    setDatePrev(dateString);
  };
  const notyf = useContext(NotyfContext);
  const [loanslist, setLoanslist] = useState([]);

  useEffect(() => {
    loanlist.filter((value) => {
      if (valitem.id === value.outils_id) {
        setLoanslist(value);
      }
      return false;
    });
  });

  // useEffect (() => {
  // }, [loanlist])

  async function btinsertMov(val) {
    await Axios.post('/api/insertloan', {
      outils_id: valitem.id,
      users_id: val.userid,
      create_time: dateprev.toString(),
      obs: val.obs,
      recep: daterecep.toString(),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setLoanlist([
          ...loanlist,
          {
            outils_id: val.id,
            users_id: val.userid,
            create_time: dateprev.toString(),
            obs: val.obs,
            recep: daterecep.toString(),
          },
        ]);
        setUpdateCde('1');
        notyf.success(`Objet sorti du Stock`);
        form.resetFields();
      }
    });
  }

  const [form] = Form.useForm();

  return (
    <Form
      name="mouvement"
      form={form}
      onFinish={btinsertMov}
      onFinishFailed={onFinishFailed}
    >
      <div className="flex justify-center justify-items-center absolute w-full  h-screen top-16 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="flex text-white text-base text-center mt-2 w-auto">
              AJOUTER UN MOUVEMENT
            </h4>
            <div className="flex flex-col bg-white text-center m-1 mb-1 p-2">
              <table>
                <thead>
                  <tr>
                    <th className="text-slate-500">Emprunté par</th>
                    <th className="text-slate-500">Départ / Retour</th>
                    <th className="text-slate-500">Commentaires</th>
                    <th className="text-slate-500">Retour au stock le</th>
                  </tr>
                </thead>
                {loanlist
                  .filter((value) => {
                    if (valitem.id === value.outils_id) {
                      return value;
                    }
                    return false;
                  })
                  .map((value, i, row) => {
                    if (i + 1 === row.length) {
                      console.log(value);
                      return (
                        <tbody key={value.id}>
                          <tr>
                            <td className="text-slate-500">{value.users_id}</td>
                            <td className="text-slate-500">
                              {value.create_time}
                            </td>
                            <td className="text-slate-500">{value.obs}</td>
                            <td className="text-slate-500">{value.recep}</td>
                          </tr>
                        </tbody>
                      );
                    }
                    return null;
                  })}
              </table>
            </div>
            {valitem.userid === 'STOCK' ? (
              <div className="flex flex-col bg-white text-center m-1 mb-1 p-2">
                <Space wrap>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="userid"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select your Name',
                      },
                    ]}
                  >
                    <Select showSearch placeholder="Emprunté par">
                      {user.map((val, index) => (
                        <Select.Option key={index} value={val.id}>
                          {val.lastname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="dateprev"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select a Date',
                      },
                    ]}
                  >
                    <RangePicker
                      format={dateFormatList}
                      type="text"
                      name="dateprev"
                      onChange={onDatePrevChange}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="obs"
                    rules={[
                      {
                        required: true,
                        message: 'Please input a Customer',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nom + Lieu Chantier"
                      type="text"
                      maxLength={20}
                      name="obs"
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      htmlType="submit"
                      onClick={() => {
                        btupdateLoanId(
                          valitem.id,
                          (valitem.loanid = 0),
                          (valitem.userid = 25)
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </div>
            ) : (
              <></>
            )}
            <div className="absolute top-3 right-3">
              <Form.Item>
                <Button
                  style={{ backgroundColor: 'white' }}
                  htmlType="button"
                  size="small"
                  onClick={onReset}
                >
                  X
                </Button>
              </Form.Item>
            </div>
            {valitem.userid === 'HORS-ST' ? (
              <CardRetour
                btupdateLoanId={btupdateLoanId}
                onFinishFailed={onFinishFailed}
                onReset={onReset}
                valitem={valitem}
                loanslist={loanslist}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
