import { Button, Form } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import '../../../src/style.css';
import { Axios } from '../../config';
import Logged from '../Header/Logged';
import CardAttestList from './CardAttestList';
import CardMatAttrib from './CardMatAttrib';
import CardMdp from './CardMdp';
import CardTraining from './CardTraining';

import NotyfContext from '../../Context/NotyfContext';

import '../../../src/style_tailwind.css';

export default function Profile() {
  const notyf = useContext(NotyfContext);

  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);
  const username = decoded.result[0].lastname;
  const idlogged = decoded.result[0].id;

  const [client, setClientlist] = useState([]);
  const [attest, setAttest] = useState([]);
  const [matattrib, setMatAttrib] = useState([]);
  const [form] = Form.useForm();
  const [typeform, setTypeForm] = useState('');
  const [traininglist, setTrainingList] = useState([]);
  const [buttonpopupattest, setButtonPopupAttest] = useState(false);
  const [buttonpopupmdp, setButtonPopupMdp] = useState(false);
  const [attestid, setAttestId] = useState('');
  const [userslist, setUserslist] = useState([]);
  const [idusername, setIdUsername] = useState(idlogged);
  const [updatedlistattest, setUpdatedListAttest] = useState(false);
  const [updatedlistmatattrib, setUpdatedListMatAttrib] = useState(false);

  const listclient = (notyf) => {
    Axios.get('/api/get_clients').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setClientlist(response.data);
      }
    });
  };

  const listattest = (notyf) => {
    Axios.get('/api/get_attest').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setAttest(response.data);
      }
    });
  };

  const listusers = (notyf) => {
    Axios.get('/api/get_users').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUserslist(response.data);
      }
    });
  };

  const listmatattrib = (notyf) => {
    Axios.get('/api/get').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setMatAttrib(response.data);
      }
    });
  };

  useEffect(() => {
    listclient();
    listattest();
    listusers();
    listmatattrib();
  }, []);

  useEffect(() => {
    listattest();
    setUpdatedListAttest(false);
  }, [updatedlistattest]);

  useEffect(() => {
    listmatattrib();
    setUpdatedListMatAttrib(false);
  }, [updatedlistmatattrib]);

  useEffect(() => {
    if (idusername === '') {
      setIdUsername(idlogged);
    }
  }, [setIdUsername, idlogged, idusername]);

  const chgPassword = async (values) => {
    await Axios.put('/api/updateusers', {
      lastname: username,
      password: values.newpassword,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        form.resetFields();
        onReset();
        notyf.success(`Mode de passe est modifié`);
        setButtonPopupMdp(false);
      }
    });
  };

  const onFinishFailed = (errorFields) => {
    if (errorFields) {
      notyf.open({ type: 'error', message: `${errorFields}` });
    }
  };

  const onReset = () => {
    form.resetFields();
    setButtonPopupMdp(false);
    setButtonPopupAttest(false);
  };
  // Card
  const btinsertTraining = async (data) => {
    if (
      data.type === 'Habilitation Electrique' ||
      data.type === 'Formation' ||
      data.type === 'Spécifique site' ||
      data.type === 'Offshore' ||
      data.type === 'Attestation Santé' ||
      data.type === 'Visa Multiple' ||
      data.type === 'Visa Unique' ||
      data.type === 'Autorisation de conduite'
    ) {
      await Axios.post('/api/insertattest', {
        userid: idusername,
        type: data.type,
        valid: data.valid.format('YYYY-MM-DD'),
        data1: data.data1.toString(),
      }).then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setTrainingList([
            ...traininglist,
            {
              userid: username,
              type: typeform,
              valid: data.valid.format('YYYY-MM-DD'),
              data1: data.data1.toString(),
            },
          ]);
          setButtonPopupAttest(false);
          notyf.success(response.data.message);
        }
      });
    }
  };

  const btupdateValid = async (data) => {
    console.log(data, attestid);
    await Axios.put('/api/updatevalid', {
      id: attestid,
      updatevalid: data.valid.format('YYYY-MM-DD'),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdatedListAttest(true);
        notyf.success('Date de validée modifiée');
      }
    });
  };

  const btupdateId = async (data) => {
    await Axios.put('/api/update', {
      id: attestid,
      updateid: 'rev',
      update: data.valid.format('YYYY-MM-DD'),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdatedListMatAttrib(true);
        notyf.success(`Date de validée modifiée`);
      }
    });
  };

  const btdeleteAttest = async (id) => {
    await Axios.delete(`/api/delete_attest/${id}`).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdatedListAttest(true);
        form.resetFields();
        notyf.success('Item supprimé de la BDD');
      }
    });
  };

  return (
    <main className="overflow-hidden mt-36 place-items-center">
      <Logged />
      <div className="flex flex-col m-2 border-2 max-w-6xl border-blue-spie bg-gray-200">
        <div className="flex flex-row bg-blue-spie m-2">
          <div className="flex bg-white m-3 border-2 border-blue-spie">
            <Button onClick={() => setButtonPopupMdp(true)}>
              {' '}
              Changer son Mot de passe
            </Button>
          </div>
          {access > 2 || access === 1 ? (
            <>
              <div className="flex bg-white m-3 border-2 border-blue-spie">
                <Button onClick={() => setButtonPopupAttest(true)}>
                  Ajouter une formation
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* Changer Mot de passe */}
        {buttonpopupmdp === true ? (
          <CardMdp
            onReset={onReset}
            onFinishFailed={onFinishFailed}
            chgPassword={chgPassword}
            username={username}
          />
        ) : (
          <></>
        )}
        {/* Ajouter une formation */}
        {buttonpopupattest === true ? (
          <CardTraining
            onReset={onReset}
            onFinishFailed={onFinishFailed}
            onFinish={btinsertTraining}
            typeform={typeform}
            setTypeForm={setTypeForm}
            client={client}
            setClientlist={setClientlist}
            userslist={userslist}
            username={username}
            access={access}
            setIdUsername={setIdUsername}
          />
        ) : (
          <></>
        )}
        {/* liste des attestations */}
        {access > 2 || access === 1 ? (
          <>
            <CardAttestList
              attest={attest}
              setAttestId={setAttestId}
              onFinishFailed={onFinishFailed}
              btupdateValid={btupdateValid}
              btdeleteAttest={btdeleteAttest}
              access={access}
              username={username}
            />
          </>
        ) : (
          <></>
        )}
        {/* liste matériel */}
        {access > 2 || access === 1 ? (
          <>
            <CardMatAttrib
              matattrib={matattrib}
              setAttestId={setAttestId}
              onFinishFailed={onFinishFailed}
              btupdateValid={btupdateId}
              btdeleteAttest={btdeleteAttest}
              username={username}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
}
