import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import React from 'react';

const { Option } = Select;

export default function CardUpdate({
  handleChangeUpdateId,
  updateid,
  update,
  setUpdate,
  onSetRevChange,
  handleChangeUser,
  userslist,
  btupdateId,
  valitem,
  onReset,
}) {
  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  return (
    <Form {...layout} name="control-ref" form={form}>
      <div className="flex justify-center justify-items-center absolute w-full  h-screen top-16 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="flex text-white text-base text-center mt-2 w-96">
              Mise à jour Item
            </h4>
            <div className="flex flex-col bg-white m-1 p-2">
              <Form.Item style={{ marginBottom: 10 }}>
                <Select
                  showSearch
                  placeholder="Select Option"
                  onChange={handleChangeUpdateId}
                  style={{ width: 250, marginRight: 10 }}
                >
                  <Option value="spie">N° Spie</Option>
                  <Option value="num">Marque</Option>
                  <Option value="ref">Type</Option>
                  <Option value="design">Désignation</Option>
                  <Option value="obs">Commentaire</Option>
                  <Option value="sn">N° de série</Option>
                  <Option value="rev">Validitée</Option>
                  <Option value="userid">Attribué à:</Option>
                </Select>
              </Form.Item>
              <Space>
                {updateid === 'spie' ||
                updateid === 'num' ||
                updateid === 'ref' ||
                updateid === 'design' ||
                updateid === 'obs' ||
                updateid === 'sn' ? (
                  <Form.Item style={{ margin: 0 }} name="texte">
                    <Input
                      placeholder="Texte"
                      value={update}
                      onChange={(e) => {
                        setUpdate(e.target.value);
                      }}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                {updateid === 'rev' ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="rev"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormatList}
                      onChange={onSetRevChange}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                {updateid === 'userid' ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="userid"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a name"
                      onChange={handleChangeUser}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      {userslist.map((val, index) => (
                        <Select.Option key={index} value={val.id}>
                          {val.lastname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}
                <Form.Item style={{ margin: 0 }}>
                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={() => {
                      btupdateId(valitem.id);
                    }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </div>
          <div className="absolute top-3 right-3">
            <Form.Item>
              <Button
                style={{ backgroundColor: 'white' }}
                htmlType="button"
                size="small"
                onClick={onReset}
              >
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
}
