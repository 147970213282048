import { Button, DatePicker, Form, Space } from 'antd';
import React, { useContext, useState } from 'react';
import NotyfContext from '../../Context/NotyfContext';
import { Axios } from '../../config';

export default function CardRetour({
  btupdateLoanId,
  onFinishFailed,
  onReset,
  valitem,
  loanslist,
}) {
  const [daterecep, setDateRecep] = useState('');

  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  const onDateRecepChange = (date, dateString) => {
    setDateRecep(dateString);
  };
  const notyf = useContext(NotyfContext);

  async function btupdateMov() {
    await Axios.put('/api/updatemov', {
      id: loanslist.id,
      updaterecep: daterecep.toString(),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        // setUpdateCde('1');
        form.resetFields();
        notyf.success(`Objet rentré au Stock`);
      }
    });
  }

  return (
    <>
      <Form
        name="retour"
        form={form}
        onFinish={btupdateMov}
        onFinishFailed={onFinishFailed}
      >
        <div>
          <div className="flex flex-col bg-white m-1 mt-0 p-2 ">
            <Space wrap>
              <Form.Item
                style={{ margin: 0 }}
                label="Retour au stock le:"
                name="daterecep"
                rules={[
                  {
                    required: false,
                    message: 'Please Select a Date',
                  },
                ]}
              >
                <DatePicker
                  format={dateFormatList}
                  type="text"
                  name="daterecep"
                  onChange={onDateRecepChange}
                />
              </Form.Item>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={() => {
                    btupdateMov(valitem.id);
                    btupdateLoanId(
                      valitem.id,
                      (valitem.loanid = 1),
                      (valitem.userid = 24)
                    );
                  }}
                >
                  Update
                </Button>
              </Form.Item>
            </Space>
          </div>
        </div>
      </Form>
    </>
  );
}
